/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppointmentAppointmentReminderStatus = 'Created' | 'Wait' | 'Done' | 'Failed';

export const AppointmentAppointmentReminderStatus = {
    Created: 'Created' as AppointmentAppointmentReminderStatus,
    Wait: 'Wait' as AppointmentAppointmentReminderStatus,
    Done: 'Done' as AppointmentAppointmentReminderStatus,
    Failed: 'Failed' as AppointmentAppointmentReminderStatus
};

