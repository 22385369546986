/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppointmentAppointmentScheduleStatus = 'Created' | 'Accepted' | 'Rejected' | 'Canceled' | 'Completed';

export const AppointmentAppointmentScheduleStatus = {
    Created: 'Created' as AppointmentAppointmentScheduleStatus,
    Accepted: 'Accepted' as AppointmentAppointmentScheduleStatus,
    Rejected: 'Rejected' as AppointmentAppointmentScheduleStatus,
    Canceled: 'Canceled' as AppointmentAppointmentScheduleStatus,
    Completed: 'Completed' as AppointmentAppointmentScheduleStatus
};

