/**
 * OpenAPI common
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppointmentnoticeStatus = 'Published' | 'Received' | 'Archived' | 'Deleted';

export const AppointmentnoticeStatus = {
    Published: 'Published' as AppointmentnoticeStatus,
    Received: 'Received' as AppointmentnoticeStatus,
    Archived: 'Archived' as AppointmentnoticeStatus,
    Deleted: 'Deleted' as AppointmentnoticeStatus
};

